import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  feature: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  allType: {
    width: '45%',
    maxWidth: '550px',
    marginRight: '15%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
      marginRight: '0',
      width: '80%',
    },
  },
  heading: {
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '4.5rem',
    },
  },
  description: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem',
    },
  },
  img: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    boxShadow: `0 5px 10px #0009`,
  },
});

function LeftFeature({ classes, content }) {
  return (
    <div className={classes.feature}>
      <div className={classes.allType}>
        <Typography variant="h4" className={classes.heading}>
          {content.title}
        </Typography>
        <Typography className={classes.description}>{content.description}</Typography>
      </div>
      <img src={content.screenshot} alt="MediVI screenshot" className={classes.img} />
    </div>
  );
}

LeftFeature.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  content: PropTypes.shape({
    description: PropTypes.string,
    screenshot: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(LeftFeature);
