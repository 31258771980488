import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '@apps/shared/src/style';

const useAboveTheFoldStyles = makeStyles(theme => {
  // eslint-disable-next-line global-require
  const background = require('./assets/AboveTheFoldBackground.svg') as string;

  return {
    heroImg: {
      width: '100vw',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      objectPosition: '0 -550px',
    },
    fullHero: {
      minHeight: '100vh',
    },
    allContent: {
      margin: '0 5rem',
      minHeight: 'calc(100vh - 15rem)',
      paddingTop: '11rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('xl')]: {
        margin: '0 10vw',
      },
    },
    typeOnDark: {
      color: colors.white,
    },
    allText: {
      [theme.breakpoints.down('sm')]: {
        width: '75vw',
      },
      [theme.breakpoints.up('xl')]: {
        width: '50vw',
        maxWidth: '900px',
      },
      width: '40vw',
      maxWidth: '600px',
    },
    heading: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '3.75rem',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '4.5rem',
      },
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    subHeading: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: '3em',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '2em',
      },
      marginTop: '1em',
      marginBottom: '5.5em',
      fontSize: '1.5rem',
    },
    subContent: {
      width: '85%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '100%',
      },
    },
    laptop: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      width: '50vw',
      maxWidth: '900px',
    },
    registerButton: {
      textTransform: 'uppercase',
      padding: '1rem 2rem',
      backgroundColor: colors.yellow,
      marginBottom: '2rem',
    },
  };
});

export default useAboveTheFoldStyles;
