import React, { useState, useEffect, FocusEvent, FormEvent, ChangeEventHandler } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { setSearchInput, setZipCode } from '@apps/shared/src/providerSearch/actions';
import IconButton from '@material-ui/core/IconButton';
import SubmitIcon from '@material-ui/icons/ArrowForward';
import { getProviders, validateZipCode } from '../actions';
import { RootState } from '../../store';
import { IncompleteTheme } from '../../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  header: theme.sidebar.headerText,
  form: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  textInput: {
    '&.MuiFormHelperText-root': {
      color: '#F57040 !important',
    },
  },
}));

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  selectedZipCode: providerSearch.selectedZipCode,
  isLoading: providerSearch.isLoadingProviderList,
  zipCodeLocation: providerSearch.zipCodeDetails,
  isZipUnrecognized: providerSearch.isZipUnrecognized,
});

type StateProps = {
  selectedZipCode: string;
  isLoading: boolean;
  isZipUnrecognized: boolean;
  zipCodeLocation: string;
};

const mapDispatchToProps = {
  setZipCode,
  getProviders,
  validateZipCode,
  setSearchInput,
};

type DispatchProps = {
  setZipCode: typeof setZipCode;
  getProviders: (selectedZipCode: string) => void;
  validateZipCode: (selectedZipCode: string) => void;
  setSearchInput: (input: string) => void;
};

type Props = StateProps & DispatchProps;

export function ZipCodeEntry({
  isLoading,
  selectedZipCode,
  zipCodeLocation,
  setZipCode,
  getProviders,
  validateZipCode,
  isZipUnrecognized,
  setSearchInput,
}: Props): JSX.Element {
  const classes = useStyles();
  const [zipCodeInput, setZipCodeInput] = useState(selectedZipCode);
  const [helperInfo, setHelperInfo] = useState({
    hasError: isZipUnrecognized,
    text: zipCodeLocation,
  });

  const isSubmitDisabled = isLoading || helperInfo.hasError || zipCodeInput === selectedZipCode;

  useEffect(() => {
    if (selectedZipCode.length === 5) validateZipCode(selectedZipCode);
  }, [selectedZipCode, validateZipCode]);

  useEffect(() => {
    setHelperInfo({ text: zipCodeLocation, hasError: isZipUnrecognized });
  }, [zipCodeLocation, isZipUnrecognized]);

  const applyZipCodeInputValidation = (value: string, isTyping: boolean): void => {
    if (value.length === 5) {
      setHelperInfo({ text: '', hasError: false });
      validateZipCode(value);
    } else if (value.length === 0 && !isTyping) {
      setHelperInfo({ text: 'Required', hasError: true });
    } else if (!isTyping) {
      setHelperInfo({ text: 'Must be 5 digits', hasError: true });
    } else {
      setHelperInfo({ text: '', hasError: false });
    }
  };

  const handleZipCodeInput: ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.currentTarget.value.replace(/\D/g, ''); // strip non-numeric
    setZipCodeInput(value);
    applyZipCodeInputValidation(value, true);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setSearchInput('');

    if (!isSubmitDisabled) {
      applyZipCodeInputValidation(zipCodeInput, false);

      if (zipCodeInput.length === 5) {
        setZipCode(zipCodeInput);
        getProviders(zipCodeInput);
      }
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    event.target.select();
  };

  return (
    <>
      <Typography className={classes.header}>Zip Code</Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          id="zip-input"
          placeholder="Zip Code"
          aria-label="Zip Code Input"
          value={zipCodeInput}
          onChange={handleZipCodeInput}
          onFocus={handleFocus}
          fullWidth
          autoFocus
          className={classes.textInput}
          autoComplete="off"
          type="tel"
          disabled={isLoading}
          error={helperInfo.hasError}
          helperText={helperInfo.text}
          InputProps={{ inputProps: { maxLength: 5 } }}
        />
        <IconButton
          color="primary"
          id="search-submit"
          edge="end"
          aria-label="submit zip-code"
          type="submit"
          disabled={isSubmitDisabled}
        >
          <SubmitIcon />
        </IconButton>
      </form>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeEntry);
