import {
  Provider,
  SortCriteria,
  SortDirection,
  SearchRadius,
  TaxonomyDetailsMap,
  TaxonomyDetails,
  ProviderTypesMap,
  ProviderListFilterValues,
  Accessibility,
  AccessibilityFilter,
} from '../types/providerSearch';
import { cleanString, stripPunctuation } from '../../utilities/stringUtilities';

export const allAccessibilities = Object.values(AccessibilityFilter);

export function isAccessibility(a: unknown): a is Accessibility {
  return Object.values(Accessibility).includes(a as Accessibility);
}
export function toAccessibility(a: unknown): Accessibility {
  return isAccessibility(a) ? a : Accessibility.Unknown;
}

type CompareFunction = (a: Provider, b: Provider) => number;

type RadiusOption = {
  radius: SearchRadius;
  desc: string;
};

// export const radiusDescriptions: Record<SearchRadius, string> = {
//   0: 'within zip code',
//   5: 'within 5 miles',
//   10: 'within 10 miles',
//   25: 'within 25 miles',
//   50: 'within 50 miles',
//   75: 'within 75 miles',
//   100: 'within 100 miles',
// };

export const radiusDescriptions: Record<SearchRadius, string> = {};

for (let i = 0; i <= 100; i++) {
  radiusDescriptions[i.toString()] = i === 0 ? 'within zip code' : `within ${i} miles`;
}

export const allRadiuses = Object.keys(radiusDescriptions) as SearchRadius[];

function radiusEntriesToRadiusOption([radius, desc]: [string, string]): RadiusOption {
  return {
    radius: radius as SearchRadius,
    desc,
  };
}
export const allOptions = Object.entries(radiusDescriptions).map(radiusEntriesToRadiusOption);

export function applicableOptions(counts: Record<SearchRadius, number>): RadiusOption[] {
  // an option is included if it adds new results
  return allOptions.filter(
    (o, i) => i === 0 || counts[o.radius] > counts[allOptions[i - 1].radius]
  );
}

function createCompoundComparison(
  comparers: CompareFunction[],
  sortDirection: SortDirection
): CompareFunction {
  return (a: Provider, b: Provider): number => {
    let sortScore = 0;
    let sortStage = 1;
    for (const comparer of comparers) {
      if (sortStage === 1 && sortDirection === 'desc') sortScore = comparer(b, a);
      else sortScore = comparer(a, b);

      if (sortScore !== 0) break;
      sortStage++;
    }
    return sortScore;
  };
}

export function compareAccessibility(a: Provider, b: Provider): number {
  return (
    allAccessibilities.indexOf(a.label2 || a.label1) -
    allAccessibilities.indexOf(b.label2 || b.label1)
  );
}
function compareDistance(a: Provider, b: Provider): number {
  return a.distance - b.distance;
}
function compareName(a: Provider, b: Provider): number {
  return a.name?.localeCompare(b.name, 'en', {
    sensitivity: 'base',
  });
}

export function sortProviders(
  providers: Provider[],
  sortCriteria: SortCriteria,
  sortDirection: SortDirection
): Provider[] {
  let comparison: CompareFunction;
  switch (sortCriteria) {
    case 'accessibility': {
      comparison = createCompoundComparison(
        [compareAccessibility, compareDistance, compareName],
        sortDirection
      );
      break;
    }
    case 'name': {
      comparison = createCompoundComparison(
        [compareName, compareAccessibility, compareDistance],
        sortDirection
      );
      break;
    }
    case 'distance':
    default: {
      comparison = createCompoundComparison(
        [compareDistance, compareAccessibility, compareName],
        sortDirection
      );
    }
  }
  return providers.sort(comparison);
}

export function isMinimumLength(searchText: string): boolean {
  return searchText.length > 2;
}

export function createRadiusFilter(radius: SearchRadius) {
  return (p: Provider): boolean => p.distance <= parseInt(radius, 10);
}
export function createAccessibilityFilter(selectedAccessibilities: Accessibility[]) {
  return (p: Provider): boolean =>
    selectedAccessibilities.includes(p.label1) ||
    (p.label2 !== undefined && selectedAccessibilities.includes(p.label2));
}

export function isProviderTypeSelected(
  taxonomyDetails: TaxonomyDetails,
  selectedProviderType: string
): boolean {
  return (
    taxonomyDetails?.category === selectedProviderType ||
    taxonomyDetails?.title === selectedProviderType
  );
}

export function createProviderTypeFilter(
  taxonomyDetailsMap: TaxonomyDetailsMap,
  selectedProviderType: string
) {
  return (p: Provider): boolean => {
    const taxonomies = [p.primaryTaxonomy];
    if (p.secondaryTaxonomies) taxonomies.push(...p.secondaryTaxonomies);
    return taxonomies.some(taxonomy => {
      return isProviderTypeSelected(taxonomyDetailsMap[taxonomy], selectedProviderType);
    });
  };
}

export function createTextFilter(searchText: string) {
  return (p: Provider): boolean => {
    const cleanedSearchText = cleanString(searchText);

    return ['name', 'address1', 'address2', 'city', 'state', 'phone'].some(field => {
      const providerVal = cleanString(p[field as keyof Provider]);

      if (field === 'phone') {
        const numText = stripPunctuation(cleanedSearchText);
        return Boolean(numText) && providerVal.includes(numText);
      }
      return providerVal.includes(cleanedSearchText);
    });
  };
}

function assignPartnerByPlan(providerList: Provider[], selectedPlanID: string): Provider[] {
  return providerList.map(provider =>
    provider.partnerPlans?.includes(selectedPlanID)
      ? {
          ...provider,
          label1: Accessibility.Recommended,
          label2: Accessibility.Contracted,
        }
      : provider
  );
}

export function filterProviderList({
  fullList,
  radius,
  searchInput,
  selectedAccessibilities,
  taxonomyDetailsMap,
  selectedProviderType,
  selectedPlanID,
}: Omit<ProviderListFilterValues, 'providerTypesMap'>): Provider[] {
  const filters: ((p: Provider) => boolean)[] = [];
  if (radius !== allRadiuses[allRadiuses.length - 1]) filters.push(createRadiusFilter(radius));
  if (selectedAccessibilities.length !== allAccessibilities.length)
    filters.push(createAccessibilityFilter(selectedAccessibilities));
  if (selectedProviderType !== '')
    filters.push(createProviderTypeFilter(taxonomyDetailsMap, selectedProviderType));
  if (isMinimumLength(searchInput)) filters.push(createTextFilter(searchInput));

  if (selectedPlanID) {
    const fullListWithPartnerPlans = assignPartnerByPlan(fullList, selectedPlanID);
    return fullListWithPartnerPlans.filter(provider => filters.every(filter => filter(provider)));
  }

  return fullList.filter(provider => filters.every(filter => filter(provider))); // :)
}

export function filterAndSortProviderList(
  fullList: Provider[],
  radius: SearchRadius,
  searchInput: string,
  selectedAccessibilities: Accessibility[],
  sortCriteria: SortCriteria,
  sortDirection: SortDirection,
  taxonomyDetailsMap: TaxonomyDetailsMap,
  selectedProviderType: string,
  selectedPlanID: string
): Provider[] {
  const filteredList = filterProviderList({
    fullList,
    radius,
    searchInput,
    selectedAccessibilities,
    taxonomyDetailsMap,
    selectedProviderType,
    selectedPlanID,
  });
  return sortProviders(filteredList, sortCriteria, sortDirection);
}

export function toggleAccessibility(
  accessibilities: Accessibility[],
  accessibilityToToggle: Accessibility
): Accessibility[] {
  if (accessibilities.includes(accessibilityToToggle))
    return accessibilities.filter(a => a !== accessibilityToToggle);
  return accessibilities.concat(accessibilityToToggle);
}

export function removeUnknownAccessibilitiesFromProviders(providers: Provider[]): Provider[] {
  for (const provider of providers) {
    provider.label1 = toAccessibility(provider.label1);
    if (provider.label2) provider.label2 = toAccessibility(provider.label2);
  }

  const order = ['Contracted', 'Recommended', 'Unknown', 'Not Recommended'];
  providers.sort((a, b) => order.indexOf(a.label1) - order.indexOf(b.label1));
  return providers;
}

export function swapSortDirection(prev: SortDirection): SortDirection {
  return prev === 'asc' ? 'desc' : 'asc';
}

export function buildProviderTypesMap(taxonomyDetailsMap: TaxonomyDetailsMap): ProviderTypesMap {
  const providerTypesMap: ProviderTypesMap = {};
  for (const taxonomyDetails of Object.values(taxonomyDetailsMap)) {
    if (providerTypesMap[taxonomyDetails.category]) {
      providerTypesMap[taxonomyDetails.category].push(taxonomyDetails.title);
    } else {
      providerTypesMap[taxonomyDetails.category] = [taxonomyDetails.title];
    }
  }
  return sortProviderTypesMap(providerTypesMap);
}

export function sortProviderTypesMap(providerTypesMap: ProviderTypesMap): ProviderTypesMap {
  const sortedProviderTypesMap: ProviderTypesMap = {};
  const sortedCategories = Object.keys(providerTypesMap).sort();
  for (const category of sortedCategories) {
    sortedProviderTypesMap[category] = providerTypesMap[category].sort();
  }
  return sortedProviderTypesMap;
}
