import axios from 'axios';
import ps, {
  ProviderSearchActionTypes,
} from '@apps/shared/src/providerSearch/types/providerSearchActions';
import p, { GetPlans } from '@apps/shared/src/planSelect/types/planActions';

export function getProviders(selectedZipCode: string): ProviderSearchActionTypes {
  return {
    type: ps.GET_PROVIDERS,
    meta: {
      arg: selectedZipCode,
    },
    payload: axios.get(`/api/provider-search/v1?zip=${selectedZipCode}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function validateZipCode(selectedZipCode: string): ProviderSearchActionTypes {
  return {
    type: ps.VALIDATE_ZIP_CODE,
    meta: {
      arg: selectedZipCode,
    },
    payload: axios.get(`/api/zip-code-validation/v1/${selectedZipCode}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getPlans(): GetPlans {
  return {
    type: p.GET_PLANS,
    payload: axios.get(`/api/v2/get-plans`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}
