import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import LeftFeature from './LeftFeature';
import RightFeature from './RightFeature';
import { features } from './content';

const styles = {
  allFeatures: {
    minHeight: '100vh',
    margin: '5vw 10vw',
  },
};

function Features(props) {
  const { classes } = props;
  const allFeatures = features.map((f, index) => {
    // alternate left and right views
    if (index % 2 === 0) {
      return <LeftFeature key={f.title} content={f} />;
    }
    return <RightFeature key={f.title} content={f} />;
  });

  return (
    <div className={classes.allFeatures} id="features">
      {allFeatures}
    </div>
  );
}

Features.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Features);
