import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import UserMenu from './UserMenu';
import NavMenu from './NavMenu';

const column = {
  display: 'flex',
  alignItems: 'center',
};
const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 0 0 1.5em',
    width: '100%',
    overflowX: 'auto',
    ...theme.header.root,
  },
  container: {
    minWidth: 'max-content',
    width: '100%',
    display: 'flex',
  },
  logo: {
    height: '50px',
    verticalAlign: 'middle',
    marginRight: '1em',
  },
  pageTitle: {
    fontFamily: theme.header.pageTitle.fontFamily,
    color: theme.header.pageTitle.color,
    fontWeight: 'bold',
    marginRight: '0.5em',
  },
  left: { ...column, justifyContent: 'flex-start' },
  center: { ...column, justifyContent: 'center' },
  right: { ...column, justifyContent: 'flex-end', flex: 1 },
});

function Header({ classes, title = '', currentUser, authBaseURL, isLoggedIn }) {
  const location = useLocation();

  return (
    <header className={classes.root}>
      <div className={classes.container}>
        <div className={classes.left}>
          <Link to="/">
            <img src="/images/medivi_logo_on_dark.svg" className={classes.logo} alt="logo" />
          </Link>
          <Typography component="h1" variant="h6" className={classes.pageTitle}>
            {title}
          </Typography>
        </div>
        {isLoggedIn && (
          <div className={classes.right}>
            {/* {location.pathname !== '/provider-search' &&
            location.pathname !== '/my-cases' &&
            location.pathname !== '/instructional-video' &&
            location.pathname !== '/member-detail' &&
            location.pathname !== '/upload-file' &&
            location.pathname !== '/contact-us' &&
            location.pathname !== '/privacy-policy' &&
            location.pathname !== '/benchmarks' &&
            location.pathname !== '/reports' &&
            location.pathname !== '/FAQ' && <NavMenu currentUser={currentUser} />} */}
            <UserMenu currentUser={currentUser} authBaseURL={authBaseURL} />
          </div>
        )}
      </div>
    </header>
  );
}

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  authBaseURL: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
Header.defaultProps = {
  title: '',
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.user.isLoggedIn,
    currentUser: state.user.currentUser,
    authBaseURL: state.user.authBaseURL,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(Header);
