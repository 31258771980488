import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import { colors } from '@apps/shared/src/style';
import background from './assets/RegisterPageBackground.svg';

import { registerPage } from './content';
import SignUpInput from './SignUpInput';

const styles = theme => ({
  heroImg: {
    minHeight: '70vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: 'center -2vh',
    },
    backgroundRepeat: 'no-repeat',
    padding: '0 10vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typeOnDark: {
    color: colors.white,
  },
  heading: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.75rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '4.5rem',
    },
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  subContent: {
    [theme.breakpoints.down('lg')]: {
      width: '75vw',
      maxWidth: '550px',
    },
    width: '40%',
    maxWidth: '700px',
  },
  subHeading: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4.5em',
      fontSize: '1rem',
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '3em',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2em',
    },
    marginTop: '1em',
    marginBottom: '5.5em',
    fontSize: '1.5rem',
  },
});

function RegisterPage({ classes, email, updateEmail, requestAccess, activeButton }) {
  return (
    <div id="register" className={classes.heroImg}>
      <Typography variant="h4" className={classnames(classes.heading, classes.typeOnDark)}>
        {registerPage.headline}
      </Typography>
      <div className={classes.subContent}>
        <Typography paragraph className={classnames(classes.subHeading, classes.typeOnDark)}>
          {registerPage.subHeading}
        </Typography>
        <SignUpInput
          email={email}
          updateEmail={updateEmail}
          requestAccess={requestAccess}
          activeButton={activeButton}
        />
      </div>
    </div>
  );
}

RegisterPage.propTypes = {
  activeButton: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
  requestAccess: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterPage);
