import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, IconButton, Grid } from '@material-ui/core';

import VisibilityAdornment from '@apps/shared/src/components/VisibilityAdornment';
import { UserState } from '@apps/shared/src/auth/types';
import { login } from '@apps/shared/src/auth/userActions';
import { LoginArg } from '@apps/shared/src/auth/userActionTypes';
import { setLoginInfo, resetRegisterPages } from '@apps/shared/src/register/actions';
import { LoginInfo, RegisterState } from '@apps/shared/src/register/types/register';
import Illustrator from '../shared/Illustrator';
import { IncompleteTheme } from '../shared/types/theme';
import InputBox from '../shared/InputBox';
import { PrimaryButton } from '../shared/button';
import BottomCredits from '../shared/BottomCredits';

const styles = makeStyles((theme: IncompleteTheme) => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  illustrator: {
    width: '50%',
  },
  loginBox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    justifyContent: 'center',
    backgroundColor: '#F9F9F9',
    alignItems: 'center',
  },
  signinBox: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    flex: 1,
  },
  signinText: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: '2.375rem',
    letterSpacing: '0em',
    marginBottom: '1rem',
    textAlign: 'left',
    color: '#37383D',
  },
  inputBox: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      borderRadius: '0.75rem',
      height: '1rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.75rem',
      boxShadow: 'rgba(33, 35, 38, 0.2) 0px 10px 10px -10px',
    },
  },
  label: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '0',
  },
  forgotPasswordBtn: {
    textAlign: 'right',
    color: '#797C80',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    marginTop: '0',
    cursor: 'pointer',
  },

  signinBtn: {
    width: '100%',
    backgroundColor: '#F07037',
    color: '#EFECE7',
    height: '3rem',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    borderRadius: '0.75rem',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#F07037',
      color: '#EFECE7',
    },
  },
  dontMessage: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  registerBtn: {
    fontSize: '1rem',
    fontWeight: 600,
    borderRadius: 0,
    padding: '0 0 4px 4px',
    color: '#F07037',
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  // //////////////////////////////

  divider: {
    margin: '0 40%',
  },
  forgotPasswordContainer: {
    textAlign: 'right',
    margin: '0 0 .5rem 0',
  },
  forgotPassword: {
    color: '#797C80',
    textTransform: 'none',
    padding: '0',
  },
  registerContainer: {
    textAlign: 'center',
  },
  registerButton: {
    margin: '5px 0',
  },
}));

type StateProps = {
  loginInfo: RegisterState['loginInfo'];
  isFetching: UserState['isFetching'];
};

type ParentProps = {
  hideRegistration?: boolean;
};

const mapStateToProps = ({
  register,
  user,
}: {
  register: RegisterState;
  user: UserState;
}): StateProps => ({
  loginInfo: register.loginInfo,
  isFetching: user.isFetching,
});

type DispatchProps = {
  setLoginInfo: typeof setLoginInfo;
  login: (arg: LoginArg) => void;
  resetRegisterPages: () => void;
};

const mapDispatchToProps = {
  setLoginInfo,
  login,
  resetRegisterPages,
};

type Props = ParentProps & StateProps & DispatchProps;

const LoginForm = ({
  loginInfo,
  isFetching,
  hideRegistration,
  setLoginInfo,
  login,
  resetRegisterPages,
}: Props): JSX.Element => {
  const classes = styles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const handleChange =
    (key: keyof LoginInfo) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setLoginInfo(key, e.currentTarget.value);
    };

  const handleSubmit =
    (loginInfo: LoginInfo) =>
    (e: React.FormEvent<Element>): void => {
      e.preventDefault();
      login({ ...loginInfo, destinationURL: '/provider-search' });
    };

  const handleRegisterClick: VoidFunction = () => {
    resetRegisterPages();
    history.push('/register');
  };

  const handleForgotPasswordClick: VoidFunction = () => {
    history.push('/password-reset');
  };

  ValidatorForm.addValidationRule('noWhitespacePadding', value => value.trim() === value);

  return (
    <div className={classes.container}>
      <div className={classes.illustrator}>
        <Illustrator />
      </div>
      <div className={classes.loginBox}>
        <div className={classes.signinBox}>
          <Typography className={classes.signinText}>Sign In</Typography>
          <div>
            <InputBox
              name="email"
              label="Email"
              id="email"
              onChangeEvent={handleChange('email')}
              value={loginInfo.email}
              className={classes.inputBox}
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'Must be valid email']}
            />
          </div>

          <div>
            <InputBox
              id="password"
              name="password"
              type="password"
              label="Password"
              className={classes.inputBox}
              onChangeEvent={handleChange('password')}
              value={loginInfo.password}
              validators={['required', 'noWhitespacePadding', 'maxStringLength:128']}
              errorMessages={[
                'This field is required',
                'No leading or trailing whitespace',
                'Password must be less than 128 characters',
              ]}
              // inputProps={{ autoCapitalize: 'none' }}
              inputProps={{
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <VisibilityAdornment
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                ),
              }}
            />
            <Grid item xs={12} className={classes.forgotPasswordContainer}>
              <Button
                onClick={handleForgotPasswordClick}
                disabled={isFetching}
                className={classes.forgotPassword}
              >
                Forgot password
              </Button>
            </Grid>
          </div>
          <PrimaryButton
            onClick={handleSubmit(loginInfo)}
            className={classes.signinBtn}
            disabled={isFetching}
            type="submit"
          >
            Sign in
          </PrimaryButton>

          {!hideRegistration && (
            <p className={classes.dontMessage}>
              <span>Don&apos;t have an account?</span>
              <IconButton className={classes.registerBtn} onClick={handleRegisterClick}>
                Register Here.
              </IconButton>
            </p>
          )}
        </div>

        <BottomCredits />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
