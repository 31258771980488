import { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import { User, LoginResponse, VerifyEmailResponse } from './types';

enum u {
  GET_USER = 'GET_USER',
  GET_USER_PENDING = 'GET_USER_PENDING',
  GET_USER_FULFILLED = 'GET_USER_FULFILLED',
  GET_USER_REJECTED = 'GET_USER_REJECTED',
  GET_USER_CANCELLED = 'GET_USER_CANCELLED',
  LOGIN = 'LOGIN',
  LOGIN_PENDING = 'LOGIN_PENDING',
  LOGIN_REJECTED = 'LOGIN_REJECTED',
  LOGIN_FULFILLED = 'LOGIN_FULFILLED',
  LOGOUT = 'LOGOUT',
  LOGOUT_PENDING = 'LOGOUT_PENDING',
  LOGOUT_REJECTED = 'LOGOUT_REJECTED',
  LOGOUT_FULFILLED = 'LOGOUT_FULFILLED',
  REDIRECT_TO = 'REDIRECT_TO',
  CLEAR_REDIRECT = 'CLEAR_REDIRECT',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_PENDING = 'VERIFY_EMAIL_PENDING',
  VERIFY_EMAIL_FULFILLED = 'VERIFY_EMAIL_FULFILLED',
  VERIFY_EMAIL_REJECTED = 'VERIFY_EMAIL_REJECTED',
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
  REQUEST_PASSWORD_RESET_PENDING = 'REQUEST_PASSWORD_RESET_PENDING',
  REQUEST_PASSWORD_RESET_FULFILLED = 'REQUEST_PASSWORD_RESET_FULFILLED',
  REQUEST_PASSWORD_RESET_REJECTED = 'REQUEST_PASSWORD_RESET_REJECTED',
  VERIFY_PASSWORD_RESET = 'VERIFY_PASSWORD_RESET',
  VERIFY_PASSWORD_RESET_PENDING = 'VERIFY_PASSWORD_RESET_PENDING',
  VERIFY_PASSWORD_RESET_FULFILLED = 'VERIFY_PASSWORD_RESET_FULFILLED',
  VERIFY_PASSWORD_RESET_REJECTED = 'VERIFY_PASSWORD_RESET_REJECTED',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  UPDATE_PASSWORD_PENDING = 'UPDATE_PASSWORD_PENDING',
  UPDATE_PASSWORD_FULFILLED = 'UPDATE_PASSWORD_FULFILLED',
  UPDATE_PASSWORD_REJECTED = 'UPDATE_PASSWORD_REJECTED',
  SET_IS_PASSWORD_RESEND_SUCCESS = 'SET_IS_PASSWORD_RESEND_SUCCESS',
  GET_AUTH = 'GET_AUTH',
  GET_AUTH_PENDING = 'GET_AUTH_PENDING',
  GET_AUTH_FULFILLED = 'GET_AUTH_FULFILLED',
  GET_AUTH_REJECTED = 'GET_AUTH_REJECTED',
  CREATE_PROFILE = 'CREATE_PROFILE',
  CREATE_PROFILE_PENDING = 'CREATE_PROFILE_PENDING',
  CREATE_PROFILE_FULFILLED = 'CREATE_PROFILE_FULFILLED',
  CREATE_PROFILE_REJECTED = 'CREATE_PROFILE_REJECTED',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED',
  CHANGE_PASSWORD_REJECTED = 'CHANGEPASSWORD_REJECTED',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_PENDING = 'DELETE_USER_PENDING',
  DELETE_USER_REJECTED = 'DELETE_USER_REJECTED',
  DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED',
  UPDATE_USER_STATE = 'UPDATE_USER_STATE',
  COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING',
}
export default u;

export const isUserActionTypes = (action: unknown): action is UserActionTypes => {
  return Object.values(u).includes((action as UserActionTypes).type);
};

export type GetUser = {
  type: typeof u.GET_USER;
  meta: { redirectTo?: string };
  payload: AxiosPromise<User>;
};

type GetUserPending = {
  type: typeof u.GET_USER_PENDING;
  meta: { redirectTo?: string };
  payload: AxiosPromise<User>;
};

type GetUserRejected = {
  type: typeof u.GET_USER_REJECTED;
  meta: { redirectTo?: string };
  payload: AxiosError<User>;
};

type GetUserFulfilled = {
  type: typeof u.GET_USER_FULFILLED;
  meta: { redirectTo?: string };
  payload: AxiosResponse<User>;
};

export type GetUserCancelled = {
  type: typeof u.GET_USER_CANCELLED;
  meta: { redirectTo?: string };
};

type GetUserActions =
  | GetUser
  | GetUserPending
  | GetUserRejected
  | GetUserFulfilled
  | GetUserCancelled;

export type LoginArg = {
  email: string;
  password: string;
  destinationURL: string;
};

export type ChangePasswordArg = {
  email: string;
  currentPassword: string;
  newPassword: string;
};

export type Login = {
  type: typeof u.LOGIN;
  payload: AxiosPromise<LoginResponse>;
  meta: {
    destinationURL: string;
  };
};

type LoginPending = {
  type: typeof u.LOGIN_PENDING;
  meta: Login['meta'];
};

type LoginRejected = {
  type: typeof u.LOGIN_REJECTED;
  payload: AxiosError<LoginResponse>;
  meta: Login['meta'];
};

export type LoginFulfilled = {
  type: typeof u.LOGIN_FULFILLED;
  payload: AxiosResponse<LoginResponse>;
  meta: Login['meta'];
};

type LoginActions = Login | LoginPending | LoginRejected | LoginFulfilled;

export type Logout = {
  type: typeof u.LOGOUT;
  payload: AxiosPromise<void>;
  meta: {
    destinationURL?: string;
  };
};

type LogoutPending = {
  type: typeof u.LOGOUT_PENDING;
  payload: Logout['payload'];
  meta: Logout['meta'];
};

type LogoutRejected = {
  type: typeof u.LOGOUT_REJECTED;
  payload: AxiosError<void>;
  meta: Logout['meta'];
};

type LogoutFulfilled = {
  type: typeof u.LOGOUT_FULFILLED;
  payload: AxiosResponse<void>;
  meta: Logout['meta'];
};

type LogoutActions = Logout | LogoutPending | LogoutRejected | LogoutFulfilled;

export type DeleteUser = {
  type: typeof u.DELETE_USER;

  payload: AxiosPromise<any>;
  meta: { redirectTo: string };
};

export type DeleteUserPending = {
  type: typeof u.DELETE_USER_PENDING;
  payload: Logout['payload'];
  meta: Logout['meta'];
};

export type DeleteUserRejected = {
  type: typeof u.DELETE_USER_REJECTED;
  payload: AxiosError<void>;
  meta: DeleteUser['meta'];
};

export type DeleteUserFulfilled = {
  type: typeof u.DELETE_USER_FULFILLED;
  payload: AxiosResponse<void>;
  meta: DeleteUser['meta'];
};

export type UpdateUserState = {
  type: typeof u.UPDATE_USER_STATE;
  payload: boolean;
};

export type DeleteUserActions =
  | DeleteUser
  | DeleteUserPending
  | DeleteUserRejected
  | DeleteUserFulfilled
  | UpdateUserState;

export type RedirectTo = {
  type: typeof u.REDIRECT_TO;
  meta: string;
};

export type ClearRedirect = {
  type: typeof u.CLEAR_REDIRECT;
};

export type VerifyEmail = {
  type: typeof u.VERIFY_EMAIL;
  meta: { saveCSRF: boolean };
  payload: AxiosPromise<VerifyEmailResponse>;
};

type VerifyEmailPending = {
  type: typeof u.VERIFY_EMAIL_PENDING;
  meta: { saveCSRF: boolean };
  payload: AxiosPromise<VerifyEmailResponse>;
};

type VerifyEmailRejected = {
  type: typeof u.VERIFY_EMAIL_REJECTED;
  meta: { saveCSRF: boolean };
  payload: AxiosError<VerifyEmailResponse>;
};

type VerifyEmailFulfilled = {
  type: typeof u.VERIFY_EMAIL_FULFILLED;
  meta: { saveCSRF: boolean };
  payload: AxiosResponse<VerifyEmailResponse>;
};

type VerifyEmailActions =
  | VerifyEmail
  | VerifyEmailPending
  | VerifyEmailRejected
  | VerifyEmailFulfilled;

export type PasswordResetArg = {
  email: string;
  destinationURL: string;
  isResend: boolean;
  application: string;
};

export type RequestPasswordReset = {
  type: typeof u.REQUEST_PASSWORD_RESET;
  meta: PasswordResetArg;
  payload: AxiosPromise<void>;
};

type RequestPasswordResetPending = {
  type: typeof u.REQUEST_PASSWORD_RESET_PENDING;
  meta: PasswordResetArg;
};

type RequestPasswordResetRejected = {
  type: typeof u.REQUEST_PASSWORD_RESET_REJECTED;
  meta: PasswordResetArg;
};

type RequestPasswordResetFulfilled = {
  type: typeof u.REQUEST_PASSWORD_RESET_FULFILLED;
  meta: PasswordResetArg;
};

type RequestPasswordResetActions =
  | RequestPasswordReset
  | RequestPasswordResetPending
  | RequestPasswordResetRejected
  | RequestPasswordResetFulfilled;

export type VerifyPasswordReset = {
  type: typeof u.VERIFY_PASSWORD_RESET;
  payload: AxiosPromise;
};
type VerifyPasswordResetPending = {
  type: typeof u.VERIFY_PASSWORD_RESET_PENDING;
  payload: AxiosPromise;
};
type VerifyPasswordResetRejected = {
  type: typeof u.VERIFY_PASSWORD_RESET_REJECTED;
  payload: AxiosError;
};
type VerifyPasswordResetFulfilled = {
  type: typeof u.VERIFY_PASSWORD_RESET_FULFILLED;
  payload: AxiosResponse;
};

export type VerifyPasswordResetActions =
  | VerifyPasswordReset
  | VerifyPasswordResetPending
  | VerifyPasswordResetRejected
  | VerifyPasswordResetFulfilled;

export type ChangePassword = {
  type: typeof u.CHANGE_PASSWORD;
  payload: AxiosPromise<void>;
  meta: {
    destinationURL: string;
  };
};
export type ChangePasswordRejected = {
  type: typeof u.CHANGE_PASSWORD_REJECTED;
  payload: AxiosPromise<void>;
  meta: { destinationURL: string };
};
export type ChangePasswordFulfilled = {
  type: typeof u.CHANGE_PASSWORD_FULFILLED;
  payload: AxiosPromise<void>;
  meta: { destinationURL: string };
};

export type ChangePasswordActions =
  | ChangePassword
  | ChangePasswordRejected
  | ChangePasswordFulfilled;

export type UpdatePassword = {
  type: typeof u.UPDATE_PASSWORD;
  payload: AxiosPromise;
};
type UpdatePasswordPending = {
  type: typeof u.UPDATE_PASSWORD_PENDING;
  payload: AxiosPromise;
};
type UpdatePasswordRejected = {
  type: typeof u.UPDATE_PASSWORD_REJECTED;
  payload: AxiosError;
};
type UpdatePasswordFulfilled = {
  type: typeof u.UPDATE_PASSWORD_FULFILLED;
  payload: AxiosResponse;
};

export type UpdatePasswordActions =
  | UpdatePassword
  | UpdatePasswordPending
  | UpdatePasswordRejected
  | UpdatePasswordFulfilled;

export type SetIsPasswordResendSuccess = {
  type: typeof u.SET_IS_PASSWORD_RESEND_SUCCESS;
  payload: boolean;
};

export type GetAuthResponse = {
  data: {
    user: User;
    authBaseURL: string;
    error: string;
  };
};

type GetAuth = {
  type: typeof u.GET_AUTH;
  payload: AxiosPromise<GetAuthResponse>;
};

type GetAuthPending = {
  type: typeof u.GET_AUTH_PENDING;
};

type GetAuthFulfilled = {
  type: typeof u.GET_AUTH_FULFILLED;
  payload: GetAuthResponse;
};

type GetAuthRejected = {
  type: typeof u.GET_AUTH_REJECTED;
};

export type GetAuthActionTypes = GetAuth | GetAuthPending | GetAuthRejected | GetAuthFulfilled;

export type CreateProfile = {
  type: typeof u.CREATE_PROFILE;
  payload: AxiosPromise;
  meta: { destinationURL: string };
};
type CreateProfilePending = {
  type: typeof u.CREATE_PROFILE_PENDING;
  payload: AxiosPromise;
};
type CreateProfileRejected = {
  type: typeof u.CREATE_PROFILE_REJECTED;
  payload: AxiosError;
};
type CreateProfileFulfilled = {
  type: typeof u.CREATE_PROFILE_FULFILLED;
  payload: AxiosResponse;
  meta: { destinationURL: string };
};
export type CompleteOnboarding = {
  type: typeof u.COMPLETE_ONBOARDING;
  payload: AxiosPromise<void>;
};

export type CreateProfileActions =
  | CreateProfile
  | CreateProfilePending
  | CreateProfileRejected
  | CreateProfileFulfilled;

export type UserActionTypes =
  | GetUserActions
  | LogoutActions
  | LoginActions
  | RedirectTo
  | ClearRedirect
  | VerifyEmailActions
  | RequestPasswordResetActions
  | SetIsPasswordResendSuccess
  | GetAuthActionTypes
  | VerifyPasswordResetActions
  | UpdatePasswordActions
  | CreateProfileActions
  | ChangePasswordActions
  | CompleteOnboarding;
