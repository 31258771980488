import u, { DeleteUserActions, UserActionTypes } from './userActionTypes';
import a, { AddPlanActionTypes, isAddPlanResponse } from '../addPlan/types/addPlanActions';
import { UserState } from './types';
import r, { isAddUserLoginResponse, RegisterActionTypes } from '../register/types/registerActions';
import { getRedirectURL } from '../../../auth/src/auth/userReducer';

export const defaultUserState: UserState = {
  isLoggedIn: false,
  isFetching: false,
  hasFetchedUser: false,
  redirectTo: '',
  csrfToken: localStorage.getItem('csrfToken') || '',
  currentUser: undefined,
  authBaseURL: '',
  isPasswordResendSuccess: false,
  passwordResetSuccess: false,
  changePasswordSuccess: false,
  isUserDeleted: false,
  isCaseMember: false,
  accessLevel: '',
  isBiometricAvailable: false,
};

// const checkBiometricAvailability = useCallback(async () => {
//   try {
//     const isAvailableBio = await NativeBiometric.isAvailable();
//     if (isAvailableBio !== undefined) {
//       setBiometricAvailable(true);
//       const isFaceID = isAvailableBio.biometryType === BiometryType.FACE_ID;
//       const isTouchID = isAvailableBio.biometryType === BiometryType.TOUCH_ID;
//       const isFingerprint = isAvailableBio.biometryType === BiometryType.FINGERPRINT;

//       if (isFaceID) {
//         setFaceID(true);
//       } else if (isFingerprint) {
//         setFingerPrint(true);
//       }
//     } else {
//       console.error('Biometric availability is undefined');
//     }
//   } catch (error) {
//     console.error('Error checking biometric availability:', error);
//   }
// }, []);

const userReducer = (
  state: UserState = defaultUserState,
  action: UserActionTypes | AddPlanActionTypes | RegisterActionTypes | DeleteUserActions
): UserState => {
  switch (action.type) {
    case u.GET_USER_PENDING:
      return { ...state, isFetching: true };
    case u.GET_USER_REJECTED:
      return { ...state, isFetching: false, hasFetchedUser: true, isLoggedIn: false };
    case u.GET_USER_FULFILLED: {
      if (!Array.isArray(action.payload.data.roles)) {
        return {
          ...state,
          isFetching: false,
          hasFetchedUser: true,
          isLoggedIn: false,
          currentUser: undefined,
        };
      }
      return {
        ...state,
        isFetching: false,
        hasFetchedUser: true,
        isLoggedIn: true,
        currentUser: action.payload.data,
        redirectTo: action.meta.redirectTo || '',
      };
    }

    case u.COMPLETE_ONBOARDING: {
      const { currentUser } = state;
      // eslint-disable-next-line
      if (currentUser)
        return {
          ...state,
          currentUser: {
            ...currentUser,
            isOnboardingComplete: true,
          },
        };
      return state;
    }

    case u.REDIRECT_TO:
      return { ...state, redirectTo: action.meta };

    case u.CLEAR_REDIRECT:
      return { ...state, redirectTo: '' };

    case u.LOGOUT_REJECTED:
    case u.LOGOUT_FULFILLED: {
      const fullName = localStorage.getItem('fullName') ?? '';
      localStorage.clear();
      localStorage.setItem('fullName', fullName);
      return {
        ...state,

        isFetching: false,
        isLoggedIn: false,
        csrfToken: '',
        currentUser: undefined,
      };
    }

    case u.LOGIN_PENDING:
      // localStorage.clear();
      return { ...state, isFetching: true };
    case u.LOGIN_REJECTED: {
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        csrfToken: '',
        currentUser: undefined,
      };
    }
    case u.LOGIN_FULFILLED: {
      const { csrfToken, user } = action.payload.data;
      const { email } = JSON.parse(action.payload.config.data);
      const isCaseMember =
        user.roles.includes('medivi-casesmember') &&
        user.roles.includes('medivi-casesubmissionmember');
      localStorage.setItem('csrfToken', csrfToken);
      localStorage.setItem('email', email);
      localStorage.setItem('fullName', user.fullName);
      localStorage.setItem('isHardSignIn', JSON.stringify(false));
      return {
        ...state,
        isFetching: false,
        csrfToken,
        isLoggedIn: true,
        currentUser: user,
        redirectTo: action.meta.destinationURL,
        changePasswordSuccess: false,
        passwordResetSuccess: false,
        isCaseMember,
      };
    }
    case u.CHANGE_PASSWORD_REJECTED: {
      return {
        ...state,
        csrfToken: '',
        redirectTo: '',
        changePasswordSuccess: false,
      };
    }
    case u.CHANGE_PASSWORD_FULFILLED: {
      localStorage.clear();
      return {
        ...state,
        isFetching: false,
        csrfToken: '',
        isLoggedIn: true,
        redirectTo: '',
        changePasswordSuccess: true,
      };
    }
    case u.VERIFY_EMAIL_PENDING: {
      return { ...state, isFetching: true };
    }
    case u.VERIFY_EMAIL_REJECTED: {
      return { ...state, isFetching: false, redirectTo: '/' };
    }
    case u.VERIFY_EMAIL_FULFILLED: {
      const { csrfToken, email, destinationURL } = action.payload.data;
      let redirectTo = destinationURL;
      redirectTo = '/create-profile';
      if (csrfToken) localStorage.setItem('csrfToken', csrfToken);
      if (email) localStorage.setItem('email', email);
      if (destinationURL === '/welcome-member') redirectTo = '/';
      return {
        ...state,
        isFetching: false,
        redirectTo,
        csrfToken,
        currentUser: {
          userID: state.currentUser?.userID || '',
          roles: state.currentUser?.roles || [],
          fullName: state.currentUser?.fullName || '',
          isOnboardingComplete: state.currentUser?.isOnboardingComplete || false,
          email,
          isPasswordExpired: state.currentUser?.isPasswordExpired || false,
        },
      };
    }

    case u.REQUEST_PASSWORD_RESET_PENDING: {
      return { ...state, isFetching: true };
    }
    case u.REQUEST_PASSWORD_RESET_REJECTED: {
      return { ...state, isFetching: false };
    }
    case u.REQUEST_PASSWORD_RESET_FULFILLED: {
      return {
        ...state,
        isFetching: false,
        redirectTo: action.meta.destinationURL,
        isPasswordResendSuccess: action.meta.isResend,
      };
    }

    case u.VERIFY_PASSWORD_RESET_REJECTED: {
      return { ...state, redirectTo: '/login', isFetching: false };
    }
    case u.VERIFY_PASSWORD_RESET_PENDING: {
      return { ...state, isFetching: true };
    }
    case u.VERIFY_PASSWORD_RESET_FULFILLED: {
      localStorage.setItem('csrfToken', action.payload.data.csrfToken);
      localStorage.setItem('fullName', action.payload.data.fullName);
      return {
        ...state,
        isFetching: false,
        redirectTo: '/update-password',
        csrfToken: action.payload.data.csrfToken,
      };
    }

    case u.UPDATE_PASSWORD_PENDING: {
      return { ...state, isFetching: true };
    }
    case u.UPDATE_PASSWORD_REJECTED: {
      return { ...state, isFetching: false };
    }
    case u.UPDATE_PASSWORD_FULFILLED: {
      const { csrfToken, user } = action.payload.data;
      localStorage.setItem('csrfToken', csrfToken);
      localStorage.setItem('fullName', action.payload.data.fullName);

      return {
        ...state,
        isFetching: false,
        redirectTo: '',
        csrfToken,
        passwordResetSuccess: true,
        currentUser: user,
        isLoggedIn: true,
      };
    }

    case u.SET_IS_PASSWORD_RESEND_SUCCESS: {
      return {
        ...state,
        isPasswordResendSuccess: action.payload,
      };
    }

    case u.GET_AUTH_PENDING:
      return { ...state, isFetching: true };
    case u.GET_AUTH_FULFILLED: {
      const { user, authBaseURL, error } = action.payload.data;
      let isCaseMember = false;
      if (user && user.roles) {
        isCaseMember =
          user.roles.includes('medivi-casesmember') &&
          user.roles.includes('medivi-casesubmissionmember');
      }
      return {
        ...state,
        isFetching: false,
        isLoggedIn: error === '',
        currentUser: user,
        authBaseURL,
        isCaseMember,
      };
    }
    case u.GET_AUTH_REJECTED: {
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        csrfToken: '',
        currentUser: undefined,
      };
    }

    case r.ADD_USER_FULFILLED: {
      if (action.payload !== null && isAddUserLoginResponse(action.payload.data)) {
        const { csrfToken, user } = action.payload.data;
        const isCaseMember =
          user.roles.includes('medivi-casesmember') &&
          user.roles.includes('medivi-casesubmissionmember');
        localStorage.setItem('csrfToken', csrfToken);
        return {
          ...state,
          csrfToken,
          currentUser: user,
          isLoggedIn: true,
          isCaseMember,
          redirectTo: action.meta.redirectTo || '',
        };
      }
      return { ...state, redirectTo: action.meta.redirectTo || '' };
    }

    case a.ADD_PLAN_FULFILLED: {
      if (isAddPlanResponse(action.payload.data))
        return { ...state, redirectTo: action.meta.redirectTo || '' };
      return state;
    }

    case u.CREATE_PROFILE_PENDING: {
      return { ...state, isFetching: true };
    }
    case u.CREATE_PROFILE_REJECTED: {
      return { ...state, isFetching: false };
    }
    case u.CREATE_PROFILE_FULFILLED: {
      const { csrfToken, user } = action.payload.data;
      localStorage.setItem('csrfToken', csrfToken);
      const isCaseMember =
        user.roles.includes('medivi-casesmember') &&
        user.roles.includes('medivi-casesubmissionmember');
      return {
        ...state,
        isFetching: false,
        csrfToken,
        isLoggedIn: true,
        currentUser: user,
        isCaseMember,
        redirectTo: getRedirectURL(action.meta.destinationURL, csrfToken),
      };
    }
    case u.DELETE_USER_PENDING: {
      return { ...state, isFetching: true };
    }
    case u.DELETE_USER_REJECTED: {
      return { ...state, isFetching: false };
    }
    case u.DELETE_USER_FULFILLED: {
      localStorage.clear();
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        isUserDeleted: true,
        csrfToken: '',
        currentUser: undefined,
        redirectTo: '/register',
      };
    }

    case u.UPDATE_USER_STATE: {
      return {
        ...state,
        isUserDeleted: false,
      };
    }

    default: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const status = (action as any)?.payload?.response?.status;
      if (action.type.endsWith('_REJECTED') && status === 401) {
        return {
          ...state,
          isLoggedIn: false,
        };
      }
      return state;
    }
  }
};

export default userReducer;
