import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  setZipCode,
  setProviderType,
  setSortCriteria,
  setSortDirection,
  setSearchRadius,
  toggleSortDirection,
  toggleAccessibility,
  setAccessibilities,
  setSearchInput,
} from '@apps/shared/src/providerSearch/actions';
import {
  SortCriteria,
  SortDirection,
  SearchRadius,
  Accessibility,
} from '@apps/shared/src/providerSearch/types/providerSearch';
import { allAccessibilities } from '@apps/shared/src/providerSearch/api/providers';
import Sidebar from '../shared/providerSearchSidebar';
import { RootState } from '../store';
import AccessibilityFilter from './filters/AccessibilityFilter';
import PlanSelectFilter from './filters/PlanSelectFilter';
import ProviderTypeFilter from './filters/ProviderTypeFilter';
import RadiusSelect from './filters/RadiusSelect';
import SortControl from './filters/SortControl';
import ZipCodeEntry from './filters/ZipCodeEntry';
import SearchBar from './SearchBar';
import SliderSelector from './filters/SliderSelector';

const useStyles = makeStyles({
  container: {
    padding: '0 1rem 4rem 1rem',
    width: 310,
    '&>div': {
      marginTop: '1.5rem',
    },
  },
  resetBtn: {
    color: '#ff0000',
    textDecoration: 'underline',
    padding: '0',
    border: 'none',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
});

const mapDispatchToProps = {
  setZipCode,
  setProviderType,
  setSortCriteria,
  setSortDirection,
  setSearchRadius,
  toggleAccessibility,
  toggleSortDirection,
  setAccessibilities,
  setSearchInput,
};

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  searchInput: providerSearch.searchInput,
  searchRadius: providerSearch.radius,
  selectedZipCode: providerSearch.selectedZipCode,
  displayPlanMessage: providerSearch.displayPlanMessage,
  planMessage: providerSearch.planMessage,
});

type DispatchProps = {
  setZipCode: typeof setZipCode;
  validateZipCode: (selectedZipCode: string) => void;
  getProviders: (arg: string) => void;
};

type StateProps = {
  searchInput: string;
  selectedZipCode: string;
  searchRadius: SearchRadius;
  displayPlanMessage: boolean;
  planMessage: string;
};

type Props = StateProps & DispatchProps & typeof mapDispatchToProps;

export function ProviderSearchFilterPane({
  selectedZipCode,
  displayPlanMessage,
  searchInput,
  planMessage,
  searchRadius,
  setZipCode,
  setProviderType,
  setSortCriteria,
  setSortDirection,
  setSearchRadius,
  toggleSortDirection,
  setAccessibilities,
  setSearchInput,
}: Props): JSX.Element {
  const classes = useStyles();
  const [input, setInput] = useState(searchInput);
  const [sliderValue, setSliderValue] = useState<number>(searchRadius);

  const resetFilter = () => {
    setInput('');
    setSearchInput('');
    setZipCode(selectedZipCode);
    setProviderType('');
    setSortCriteria('accessibility' as SortCriteria);
    setSortDirection('desc');
    setAccessibilities(allAccessibilities as Accessibility[]);
    toggleSortDirection();
    setSliderValue(25);
    setSearchRadius('25' as SearchRadius);
  };

  return (
    <Sidebar hideButton className={classes.container} toggleButtonTitle="Provider Search Filters">
      <div style={{ textAlign: 'right', margin: '1rem 0' }}>
        <button onClick={resetFilter} className={classes.resetBtn} type="button">
          Clear
        </button>
      </div>
      {selectedZipCode !== '' && (
        <>
          {displayPlanMessage && (
            <Typography style={{ fontStyle: 'italic', marginTop: '3%', paddingLeft: '2%' }}>
              {planMessage}
            </Typography>
          )}
          <SearchBar input={input} setInput={setInput} />
          <PlanSelectFilter />
        </>
      )}
      <div>
        <ZipCodeEntry />
        {/* {selectedZipCode !== '' && <RadiusSelect />} */}
        {selectedZipCode !== '' && (
          <SliderSelector sliderValue={sliderValue} setSliderValue={setSliderValue} />
        )}
      </div>
      {selectedZipCode !== '' && (
        <>
          <ProviderTypeFilter />
          <AccessibilityFilter />
          <SortControl />
        </>
      )}
    </Sidebar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSearchFilterPane);
