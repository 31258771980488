import React, { useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import { Redirect } from 'react-router';
import { UserState } from '@apps/shared/src/auth/types';
import { useTheme, useMediaQuery, Theme } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { colors, hexToRgba } from '@apps/shared/src/style';
import Header from './Header';
import Footer from './Footer';
import MediVIForPartners from './MediVIForPartners';
import MediVIForMembers from './MediVIForMembers';
import { roles as mediviRoles } from '../shared/globals';
import { RootState } from '../store';
import MediviMobile from './MediviMobile';

const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    width: '100vw',
    borderBottom: `1px solid ${colors.yellow}`,
    position: 'absolute',
    backgroundColor: hexToRgba(colors.yellow, 0.15),
    marginTop: '5rem',
  },
  tab: {
    margin: '1rem 2rem',
    fontSize: '1.5rem',
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      fontSize: '1.25rem',
    },
  },
  indicator: {
    backgroundColor: colors.yellow,
    height: '0.25rem',
    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '0.25rem',
    },
  },
  selected: {
    color: colors.yellow,
  },
  wrapper: {
    alignItems: 'flex-start',
  },
}));

function getRedirectLocation(userRoles?: string[]): string {
  // const hasBenchmarksAccess = userRoles && userRoles.indexOf(mediviRoles.benchmarks) > -1;
  return '/provider-search';
}

function mapStateToProps({ user }: RootState): StateProps {
  return {
    isLoggedIn: user.isLoggedIn,
    currentUser: user.currentUser,
  };
}

type StateProps = {
  isLoggedIn: UserState['isLoggedIn'];
  currentUser: UserState['currentUser'];
};

export function LandingPage({ isLoggedIn, currentUser }: StateProps): JSX.Element {
  const classes = useStyles();
  const [userType, setUserType] = useState('member');
  const theme = useTheme();
  const displayVertical = useMediaQuery(theme.breakpoints.down('sm'));

  const handleUserTypeChange = (e: React.ChangeEvent<{}>, newValue: string): void => {
    setUserType(newValue);
  };

  const tabClassOverride = {
    root: classes.tab,
    selected: classes.selected,
    wrapper: classes.wrapper,
  };

  const content = userType === 'partner' ? <MediVIForPartners /> : <MediVIForMembers />;

  return isLoggedIn ? (
    <Redirect
      to={
        currentUser?.roles && currentUser.roles.length > 0
          ? getRedirectLocation(currentUser?.roles)
          : '/login'
      }
    />
  ) : (
    <>
      <Header />
      <div className={classes.tabContainer}>
        <Tabs
          classes={{ indicator: classes.indicator }}
          value={userType}
          onChange={handleUserTypeChange}
          id="top"
          orientation={displayVertical ? 'vertical' : undefined}
        >
          <Tab value="member" label="MediVI for Members" classes={tabClassOverride} />
          <Tab value="partner" label="MediVI for Partners" classes={tabClassOverride} />
        </Tabs>
      </div>
      {content}
      <MediviMobile />
      <Footer setUserType={setUserType} userType={userType} />
    </>
  );
}

export default connect(mapStateToProps)(LandingPage);
