import React from 'react';

import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { memberIntro } from './content';
import useAboveTheFoldStyles from './useAboveTheFoldStyles';
import { IncompleteTheme } from '../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  mobileSection: {
    width: '100vw',
    background: '#8887870D',
  },
  allContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8%',
    gap: '10%',
  },
  mobile: {
    width: '50%',
    height: '50%',
  },
  phoneBanner: {
    width: '100%',
    height: '100%',
  },
  heading: {
    color: '#EEAA21',
  },
  upcomingTitle: {
    fontSize: '.9rem',
    fontWeight: 600,
  },
  title: {
    fontWeight: 600,
  },
  featureList: {
    marginTop: '1rem',
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    borderLeft: '0.2rem solid #EEAA21',
    padding: '0 0.5rem',
    color: '#3B3B3BD4',
    fontWeight: 600,
  },
}));
function MediviMobile(): JSX.Element {
  const classes = useStyles();
  // eslint-disable-next-line global-require
  const mobile = require('./assets/MediviMobile.png') as string;

  return (
    <div className={classes.mobileSection}>
      <section className={classes.allContent}>
        <div className={classes.mobile}>
          <img
            src={mobile}
            alt="Mobile with screenshot of ProviderSearch"
            className={classes.phoneBanner}
          />
        </div>
        <div>
          <div className={classes.heading}>
            <Typography variant="h6" className={classes.upcomingTitle}>
              {' '}
              COMING IN 2024
            </Typography>
          </div>
          <div>
            <Typography variant="h4" className={classes.title}>
              MediVI App
            </Typography>
            <div className={classes.featureList}>
              <Typography className={classes.list}>Enhanced Provider Search</Typography>
              <Typography className={classes.list}>Live Case Status Updates</Typography>
              <Typography className={classes.list}>Case Submission</Typography>
              <Typography className={classes.list}>Click To Call</Typography>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MediviMobile;
