import { clearRedirect, redirectTo, changePassword } from '@apps/shared/src/auth/userActions';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import UserCard from '@apps/shared/src/auth/UserCard';

const styles = {
  inputWrapper: {
    width: '100%',
    marginTop: '10px',
  },
  ahref: {
    textDecoration: 'none',
  },
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      rePassword: '',
      errors: {
        newPassword: '',
        rePassword: '',
      },
      hasMinChar: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(clearRedirect()); // Clear any redirect before leaving
  }

  setValue = name => e => this.setState({ [name]: e.target.value });
  // Move validation to the reducer
  validate = () => {
    const errors = {
      currentPassword: '',
      newPassword: '',
      rePassword: '',
    };
    const { currentPassword, newPassword, rePassword } = this.state;

    if (currentPassword.trim() !== currentPassword)
      errors.currentPassword = 'No leading or trailing whitespace';
    // else if (currentPassword.length < 12) errors.currentPassword = 'Minimum 12 characters';
    else if (currentPassword.length > 128) errors.currentPassword = 'Maximum 128 characters';

    if (newPassword.trim() !== newPassword)
      errors.newPassword = 'No leading or trailing whitespace';
    else if (newPassword.length < 12) errors.newPassword = 'Minimum 12 characters';
    else if (newPassword.length > 128) errors.newPassword = 'Maximum 128 characters';

    if (newPassword !== rePassword) errors.rePassword = 'Passwords must match';

    this.setState({ errors });

    const errValues = Object.values(errors);
    for (const err of errValues) {
      if (err !== '') return false;
    }
    return true;
  };

  checkPassword = password => {
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let hasMinChar = false;

    if (password.length >= 12) {
      hasMinChar = true;
    }
    if (/[A-Z]/.test(password)) {
      hasUppercase = true;
    }
    if (/[a-z]/.test(password)) {
      hasLowercase = true;
    }
    if (/[0-9]/.test(password)) {
      hasNumber = true;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      hasSpecialChar = true;
    }

    this.setState({
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      hasMinChar,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) this.handlePasswordChange();
  };

  handlePasswordChange = () =>
    this.props.dispatch(
      changePassword(
        localStorage.getItem('email'),
        this.state.currentPassword,
        this.state.newPassword
      )
    );

  handleCancel = () => this.props.history.goBack();

  handleChange = event => {
    const {
      target: { value },
    } = event;
    this.checkPassword(value);
    this.setState({ newPassword: value });
  };

  render() {
    const { classes } = this.props;
    const changePasswordForm = (
      <form onSubmit={this.handleSubmit}>
        <FormControl className={classes.inputWrapper} error={!!this.state.errors.newPassword}>
          <InputLabel htmlFor="currentPassword">Current Password</InputLabel>
          <Input
            id="currentPassword"
            value={this.state.currentPassword}
            onChange={this.setValue('currentPassword')}
            type="password"
            fullWidth
            aria-describedby="passwordErrorText"
          />
          <FormHelperText id="passwordErrorText">
            {this.state.errors.currentPassword}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.inputWrapper} error={!!this.state.errors.newPassword}>
          <InputLabel htmlFor="newPassword">New Password</InputLabel>
          <Input
            id="newPassword"
            value={this.state.newPassword}
            onChange={this.handleChange}
            type="password"
            fullWidth
            aria-describedby="passwordErrorText"
          />
          <FormHelperText id="passwordErrorText">{this.state.errors.newPassword}</FormHelperText>
          {this.state.newPassword && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {[
                { label: '12 Characters', state: this.state.hasMinChar },
                { label: 'Uppercase', state: this.state.hasUppercase },
                { label: 'Lowercase', state: this.state.hasLowercase },
                { label: 'Number', state: this.state.hasNumber },
                { label: 'Special Character', state: this.state.hasSpecialChar },
              ].map(({ label, state }) => (
                <span key={label}>
                  <span style={{ color: state ? 'green' : 'red' }}>
                    {state ? '\u2714' : '\u2718'} <span style={{ color: 'black' }}>{label}</span>
                  </span>
                </span>
              ))}
            </div>
          )}
        </FormControl>
        <FormControl className={classes.inputWrapper} error={!!this.state.errors.rePassword}>
          <InputLabel htmlFor="rePassword">Re-enter New Password</InputLabel>
          <Input
            id="rePassword"
            value={this.state.rePassword}
            onChange={this.setValue('rePassword')}
            type="password"
            fullWidth
            aria-describedby="rePasswordErrorText"
          />
          <FormHelperText id="rePasswordErrorText">{this.state.errors.rePassword}</FormHelperText>
        </FormControl>
        <Button
          style={{ margin: '3em auto 1em' }}
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
        >
          UPDATE PASSWORD
        </Button>
        <Button variant="contained" color="primary" fullWidth onClick={this.handleCancel}>
          CANCEL
        </Button>
      </form>
    );
    const continueLink = this.props.registerDestinationURL
      ? this.props.registerDestinationURL
      : '/login';
    const isMediVIMobile = continueLink === '/medivi-mobile-login';
    const changePasswordSuccess = (
      <>
        <Typography variant="subtitle1" style={{ marginBottom: '2em' }}>
          Congratulations, your password has been successfully changed.
        </Typography>
        {isMediVIMobile ? (
          <Typography>Please return to the MediVI Mobile app to continue.</Typography>
        ) : (
          <a
            href={continueLink}
            alt="Continue to application home"
            title="Continue to application home"
            className={classes.ahref}
          >
            <Button variant="contained" color="primary" fullWidth>
              Return to Login page
            </Button>
          </a>
        )}
      </>
    );

    return this.props.redirectTo !== '' ? (
      <Redirect to={this.props.redirectTo} />
    ) : (
      <UserCard showLogo>
        {this.props.changePasswordSuccess ? changePasswordSuccess : changePasswordForm}
      </UserCard>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  changePasswordSuccess: PropTypes.node,
  redirectTo: PropTypes.string,
};
ChangePassword.defaultProps = {
  redirectTo: '',
  changePasswordSuccess: undefined,
};

function mapStateToProps(state) {
  return {
    redirectTo: state.user.redirectTo,
    changePasswordSuccess: state.user.changePasswordSuccess,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ChangePassword));
