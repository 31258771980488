import React, { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import { colors } from '@apps/shared/src/style';

const styles = theme => ({
  footerBackground: {
    background: colors.grey82,
    minHeight: '20vh',
    borderTop: `5px solid ${colors.orange}`,
    color: colors.white,
    padding: '5vh 10vw',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  navLinks: {
    display: 'flex',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5rem',
    },
  },
  aTagLink: {
    textDecoration: 'none',
    color: colors.white,
  },
  subHeading: {
    color: colors.yellow,
    marginBottom: '30px',
    lineHeight: '1',
  },
  list: {
    listStyle: 'none',
    padding: '0',
    marginRight: '5vw',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  },
  firstListItem: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '5vw',
    },
  },
  listItem: {
    marginBottom: '15px',
  },
  copyrightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: '7vh',
      alignItems: 'flex-start',
    },
  },
  copyrightSectionText: {
    color: colors.grey,
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.25rem',
    },
  },
  logo: {
    width: '150px',
    marginBottom: '15%',
    [theme.breakpoints.down('sm')]: {
      margin: '5px 0 5% 0',
    },
    [theme.breakpoints.up('xl')]: {
      width: '225px',
    },
  },
});

function Footer({ classes, setUserType, userType }) {
  const thisYear = new Date().getFullYear();

  useEffect(() => {
    const scrollToFeatures = () => {
      if (userType !== 'partner') {
        setUserType('partner');
      }
      const featuresElement = document.getElementById('features');
      if (featuresElement) {
        const topOfElem = featuresElement.offsetTop - 200;
        window.scrollTo({ top: topOfElem, behavior: 'smooth' });
      }
    };

    const handleFeaturesClick = () => {
      scrollToFeatures();
    };

    const featuresButton = document.getElementById('featuresButton');
    if (featuresButton) {
      featuresButton.addEventListener('click', handleFeaturesClick);
    }

    return () => {
      if (featuresButton) {
        featuresButton.removeEventListener('click', handleFeaturesClick);
      }
    };
  }, [userType, setUserType]);

  return (
    <footer className={classes.footerBackground}>
      <div className={classes.container}>
        <div className={classes.navLinks}>
          <div className={classes.firstListItem}>
            <Typography
              variant="subtitle1"
              className={classnames(classes.subHeading, classes.link)}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              MediVI
            </Typography>
            <ul className={classes.list}>
              <ButtonBase
                id="featuresButton"
                className={classnames(classes.listItem, classes.link)}
              >
                <li>Features</li>
              </ButtonBase>
            </ul>
          </div>
          <div>
            <Typography variant="subtitle1" className={classes.subHeading}>
              <a
                href="https://6degreeshealth.com"
                className={classnames(classes.aTagLink, classes.link, classes.subHeading)}
              >
                6 Degrees Health
              </a>
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <a
                  href="https://6degreeshealth.com/about-us/"
                  className={classnames(classes.aTagLink, classes.link)}
                >
                  About
                </a>
              </li>
              <li className={classes.listItem}>
                <a
                  href="https://6degreeshealth.com/cost-containment"
                  className={classnames(classes.aTagLink, classes.link)}
                >
                  Cost Containment
                </a>
              </li>
              <li>
                <a
                  href="https://6degreeshealth.com/transplant-specialty"
                  className={classnames(classes.aTagLink, classes.link)}
                >
                  Transplant & Specialty
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.copyrightSection}>
          <Typography className={classes.copyrightSectionText}>POWERED BY:</Typography>
          <a href="https://6degreeshealth.com">
            <img
              src="/images/6DHLogoOnDark.png"
              alt="6 Degrees Health Logo"
              className={classes.logo}
            />
          </a>
          <Typography paragraph className={classes.copyrightSectionText}>
            Copyright {thisYear} 6 Degrees Health, All rights reserved
          </Typography>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setUserType: PropTypes.func,
  userType: PropTypes.string,
};

export default withStyles(styles)(Footer);
